/**
 * 前后台统一弹出模块
 * 不包含简历操作后台
 * 如果出现不统一，以此为准
 */
/*<div class="modal fade matchBaseModal" id="" >
	<div class="modal-center">		
		<div class="modal-dialog ">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-header-wrap">
						<div class="modal-close">
							<a href="javascript:;"  data-dismiss="modal" ><i class="i i-close">关闭</i></a>
						</div>
						<h4 class="modal-title">title</h4>
					</div>
				</div>
				
				<div class="modal-body">
					<div class="modal-body-wrap">
						<div class="modal-body-content">
							内容区
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<div class="modal-footer-wrap text-right">
						<button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary">save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>*/
/*弹出居中*/
/*modal 居中*/
/*效果同modal 透明度渐变*/
.modal .modal-center{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.modal .modal-center div.modal-dialog { /* 初始化默认样式*/
    margin: 0 auto;
    transform: none;
}
/*modal end*/

/**
 * modal 响应式调整
 */
.modal .modal-dialog {
    width: 100% !important;
}
.modal .modal-center{
	width: 700px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-center {
    width: 100%;padding: 0 15px;
    max-width: 700px;
  }
}
/*modal 响应式调整 end*/

/**
 * modal 样式初始化
 * 如果需要设置背景：modal-header-wrap设置背景色
 * header
 */

.matchBaseModal .modal-header {
  padding: 0;border-bottom: 0;margin-bottom: -1px;
  border-top-left-radius: 6px;border-top-right-radius: 6px;overflow: hidden;
  /*border-left-width: 5px;border-left-style: solid;*/
}
.matchBaseModal .modal-header .modal-header-wrap {
	padding: 0 15px;border-bottom: 1px solid #dcdcdc;
	height: 40px;line-height: 40px;
}
.matchBaseModal .modal-header .modal-title {line-height: inherit;}
.matchBaseModal .modal-header .modal-close {line-height: inherit;float: right;font-size: 0;color: #999;}
.matchBaseModal .modal-header .modal-close a{text-decoration: none;}

.matchBaseModal .modal-header .modal-close .s-close{display: inline-block;vertical-align: middle;}
/*header end*/	

/*body*/
.matchBaseModal .modal-body{padding:0;}
.matchBaseModal .modal-body .modal-body-wrap{padding: 15px;/*border-left-width: 5px;border-left-style: solid;*/}
/*body end*/

/*foot*/
.matchBaseModal .modal-footer{
	padding:0;border-top: 0;margin-top: -1px;
	border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;overflow: hidden;
	/*border-left-width: 5px;border-left-style: solid;*/
}
.matchBaseModal .modal-footer .modal-footer-wrap{padding: 0 15px 20px;}
.matchBaseModal .modal-footer .modal-footer-wrap:empty{padding-top: 0; padding-bottom: 5px;}

/*foot end*/
.matchBaseModal .modal-content{
	min-height: 240px;
}
/*modal 样式初始化 end*/


/**
 * 以下是单个页面的样式
 */
.resumeMatchwithJobModal .modal-center{
	width: 95vw;
	max-width: 1400px;
}
.resumeMatchwithJobModal .modal-body-content{
	display: flex;height: calc(100vh - 100px);
}
.resumeMatchwithJobModal .resumeM,
.resumeMatchwithJobModal .jobDescriptionM{
	flex: 1;display: flex;flex-direction: column;
}
.resumeMatchwithJobModal .title{
	flex: 25px 0 0;
}
.resumeMatchwithJobModal .cont{
	flex: 1;border-radius: 4px;
	max-height: 900px;
	border: 1px solid #333;
}
.resumeMatchwithJobModal .resumeM .cont{
	margin-right: 15px;
}
.resumeMatchwithJobModal .jobDescriptionM .cont{
	overflow: auto;    word-break: break-word;padding: 15px;
}
@media only screen and (max-width: 992px ) {
	.resumeMatchwithJobModal .modal-center{
		width: 100%;
	}
}
@media only screen and (max-width: 768px ) {
	.resumeMatchwithJobModal .modal-body {
	    max-height: 90vh;
	    overflow: auto;
	}
	.resumeMatchwithJobModal .modal-body-content {
    	flex-direction: column;
    }
    .resumeMatchwithJobModal .resumeM .cont {
	    margin-right: 0;margin-bottom: 15px;
	}
}


/*前台后台大长弹出*/
.notLoggedInModal .modal-body .cont{
	margin-bottom: 25px;
}
.notLoggedInModal .modal-body .cont .left{
	padding-left: 100px;
	font-size: 18px;
}
.notLoggedInModal .modal-body .cont .left .title,
.modal .modal-body .cont .left p{
	margin-bottom: 15px;
}
.notLoggedInModal .modal-body .cont .left h3{
	font-weight: 400;
}
.notLoggedInModal .modal-body .cont .rightbox{
	height: 250px;
    width: 95%;
    padding: 20px;
	background: #6aa944;
	position: relative; 
	
}
.notLoggedInModal .modal-body .cont .rightbox p{
	margin-bottom: 10px;
	color: #fff;
}
.notLoggedInModal .modal-body .cont .rightbox .title{
	margin-bottom: 25px;margin-top: 0;
	color: #fff;
}
.notLoggedInModal .modal-body .cont .rightbox .icon-wrapp{
	position: absolute;
	right: 20px;
	bottom: 25px;
}
.notLoggedInModal .modal-body .cont .rightbox .icon-wrapp .icon{
	font-size: 120px;
	color: #fff;
}
.notLoggedInModal .cont .rightbox .item{height: 235px;}

@media only screen and (max-width: 992px ) {
	.notLoggedInModal .rightbox{display: none;}
	.notLoggedInModal .modal-body .cont .left{padding-left: 15px;display: table;margin: 0 auto;}
	.notLoggedInModal .modal-body .cont .left > div{height: auto !important;}
	.notLoggedInModal .modal-center {max-width: 600px;}
	.notLoggedInModal .container{width: auto;}
	.notLoggedInModal .modal-center{width: 600px;}
}
@media only screen and (max-width: 767px ) {
	.notLoggedInModal .modal-center{width: 100%;}
	.notLoggedInModal .modal-body .cont .left h3{font-size: 18px;}
	.notLoggedInModal .modal-body .cont .left {font-size: 14px;}
	.notLoggedInModal .modal-body .cont .left .title, 
	.notLoggedInModal .modal-body .cont .left p {margin-bottom: 10px;}
}

@media (min-width: 768px){
	.notLoggedInModal .container {
	    width: 750px;
	    padding: 0;
	}
}
@media (min-width: 992px){
	.notLoggedInModal .container {
	    width: 970px;
	    padding: 0;
	}
}


/*前台后台大长弹出*/
/*-------*/
.onlineResumeModal .modal-center{
	max-width: 800px;width: 100%;
}
/*-------------*/

/*covert 上传错误*/

.uploadErrorModal .modal-content{
	height: 420px;
}
.uploadErrorModal .modal-center{
	width: 450px;
}
.uploadErrorModal .modal-body .cont > div{
	margin-bottom: 30px;
}
.uploadErrorModal .modal-body .cont{
	font-size: 18px;
}
.uploadErrorModal .modal-body .cont p{
	margin-bottom: 20px;
}
.uploadErrorModal .modal-body .cont .icon-youjian{
	vertical-align: middle;
	margin-right: 5px;
}
.uploadErrorModal .modal-body .cont h4{
	font-weight: 500;
	font-size: 24px;
}
.uploadErrorModal .modal-body .cont {
	width: 100%;
    height: 140px;
    display: table;
    margin-bottom: 0; 
}
.uploadErrorModal .modal-body .cont h3{
	line-height: 2;
    display: table-cell;
    vertical-align: top;
}
@media only screen and (max-width: 767px ) {
	.uploadErrorModal .modal-dialog, .uploadErrorModal .modal-content { height: auto; }
	.uploadErrorModal .modal-body .cont h4 {font-size: 18px;}
	.uploadErrorModal .modal-body .cont p {font-size: 14px;margin-bottom: 10px;}
	.uploadErrorModal .modal-body .cont > div {margin-bottom: 20px;}
}
/*convert 上传错误 end*/

/*uploadFormatErrorModal*/

.uploadFormatErrorModal .modal-content h3{
	line-height: 145px;
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 0;
	text-align: left;

}
.uploadFormatErrorModal .modal-content button.close i.icon {
	font-size: 16px;
}
.uploadFormatErrorModal .modal-body .modal-header{
	padding: 0 0 5px 0;
	border-bottom: 2px dashed #d2d2d2;
}
.uploadFormatErrorModal .modal-body .modal-footer{
	padding: 15px 0;
	border: none;
}
.uploadFormatErrorModal .modal-center{
	width: 500px;
}
.uploadFormatErrorModal .modal-content{
	height: auto;
}

.uploadFormatErrorModal .modal-body .modal-header strong{
	font-size: 24px;
	color: #333;
}
@media only screen and (max-width: 767px ) {
	.uploadFormatErrorModal .modal-content h3{font-size: 18px;}
}
/*uploadFormatErrorModal end*/